$blue: rgb(72, 109, 209);
$blue-hover: rgb(63, 97, 189);
$white-blue: rgb(90, 123, 212);
$whiter-blue: rgb(109, 136, 216);
$grey-blue: #557a95;

$mui-primary: #3f51b5;
$mui-secondary: #f50057;

$orange: orange;

$red: rgb(196, 37, 37);
$red-dim: rgb(207, 71, 71);
$red-hover: rgb(177, 33, 33);
$red-active: rgb(158, 29, 29);

$dark-red: rgb(119, 24, 24);
$dark-red-hover: rgb(104, 20, 20);
$dark-red-active: rgb(87, 17, 17);

$green: #159c2b;

$not-white: rgb(250, 250, 250);
$almost-white: rgb(241, 241, 241);
$basically-white: rgb(235, 235, 235);
$less-white: rgb(230, 230, 230);
$lesser-white: rgb(216, 216, 216);
$grey: rgb(175, 175, 175);
$graphite-hover: rgb(87, 87, 87);
$graphite: rgb(73, 73, 73);

$font: Ubuntu, Helvetica, Arial, sans-serif;

$smaller-font-size: 12px;
$small-font-size: 15px;
$normal-font-size: 18px;
$bigger-font-size: 21px;
$header-font-size: 25px;
$big-header-font-size: 30px;
$huge-font-size: 40px;


div{
    font-family: Roboto,Helvetica,Verdana,sans-serif;
}

a{
    text-decoration: none !important;
    color: inherit !important;
}

#divCarousel{
    padding : 70px 120px 50px ;
    @media screen and (max-width: 830px) {
        padding: 0px !important;
    }
}

.logoepv{
    @media screen and (max-width: 830px) {
        visibility: hidden;
    }
    opacity: 1;
    align-self: center;
    width: auto !important;
    height: 100%;
    right: 0px;
    top: 0px;
    position: absolute;
}
.divepv{
    &:hover{
        .texteepv{
            visibility: visible;
            opacity: 1;
        }
        .logoepv{
            cursor: help;
        }
    }
}
.texteepv{
    background-color: #0E3B85;
    position: absolute;
    color: black;
    right: 0;
    margin: 5px;
    top: 65px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    width: 60%;
    transition: opacity 0.3s, visibility 0.3s;
}

.titreTissuTech{
    display: flex;
    justify-content: center;
    width: 80%;
    height: 100%;
    align-items: center;
    padding-right: 10%;
    transition: font-size 1s;
    font-family: Segoe UI,Roboto,Helvetica,Verdana,sans-serif !important;
    @media screen and (max-width: 830px){
        width: 100%;
        padding-right:0;
        font-size: 2rem !important;
    }
}
.titreDroiteTissuTech{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    transition: font-size 1s;
    padding-left: 20%;
    @media screen and (max-width: 830px){
        padding-left: 0;
        font-size: 2rem !important;
    }
}

.gaucheTissuTech{
    width:70%;
    margin-left: -20%;
    height:90vh;
    border-right: solid 3px red;
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    tranform: skew(-30deg);
    overflow: hidden;
    transition: width 1s, background-size 1s;
    &:hover{
        width:100%;
        background-size: 120%;
        .titreTissuTech{
            font-size: 5rem;
        }
    }
    @media screen and (max-width: 830px){
        width: 100%;
        margin-left: 0;
        border: none;
        height:40vh;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
        tranform: skew(0deg);
    }
}
.droiteTissuTech{
    width:70%;
    height:90vh;
    margin-right: -20%;
    background:#162933;
    border-left: solid 3px red;
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    tranform: skew(-30deg);
    overflow: hidden;
    transition : width 1s ease, background-size 1s ease, font-size 1s ease;
    &:hover{
        width:100%;
        background-size: 120%;
        font-size: 5rem;
        .titreDroiteTissuTech{
            font-size: 5rem;
        }
    }
    @media screen and (max-width: 830px){
        width: 100%;
        margin-right: 0;
        border: none;
        height:40vh;
        -webkit-transform: skew(0deg);
        -moz-transform: skew(0deg);
        -o-transform: skew(0deg);
        tranform: skew(0deg);
    }
}

.ImageJournal {
        color: black;
        background-image: url("./components/ressources/journal1.png");
        width: 700px;
        height: 850px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &:hover{
            .texteImageJournal{
                visibility: visible;
            }
        }
        @media screen and (max-width: 830px){
            width: 100% !important;
            height: 100% !important;
        }
}
.ImageJournal2 {
    background-image: url("./components/ressources/journal2.jpg") !important;
    background-position: center;
}
.texteImageJournal{
    visibility: hidden;
    background-color: #0E3B85;
    color: white;
    height: 100%;
    margin: 0;
    opacity: 0.85;
}
.MuiTypography-displayBlock{
    font-family: Roboto,Helvetica,Verdana,sans-serif !important;
    font-size: 0.875rem !important;
    text-transform: uppercase;
    &:hover {
        font-size:0.9rem !important;
    }
}
.Banner{
    height: 500px !important;
    @media screen and (max-width: 1200px){
        height: 450px !important;
    }
    @media screen and (max-width: 1000px){
        height: 400px !important;
    }
    @media screen and (max-width: 830px){
        height: 100% !important;
    }
}
.Banner .Content{
    text-align: center;
}

.MuiDrawer-paperAnchorDockedRight{
    background-color: #0E3B85 !important;
    border: none !important;
    width: 100%;
}

.Carousel .Button{
    opacity: 0 !important;/*changer a 1 pour avoir les fleches du carousel*/
    background: transparent;
}
.Carousel .ButtonWrapper{
    height: 0;
    top: 47%;
}
.boutonGrid{
    color: white !important;
    font-size: 25px;
    border: 3px solid white !important;
    text-align: center;
}
.flecheTexte{
    font-size: 2rem;
    width: 20% !important;
    align-self: center;
    position: absolute;
    color: white !important;
    margin-left: 50% !important;
    transition: width 1s !important;
}
.fleche{
    position: absolute;
    transition: width 1s !important;
}
.texteCache{
    opacity: 0;
    transition: opacity 1s !important;
    font-family: Segoe UI,Roboto, Helvetica, Arial, sans-serif !important;
}
.divSavoirFaire{
    width: 60%;
    display: flex;
    transition: width 1s !important;
    /*
    background-image: url("./components/ressources/background2.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    */
    background-color: grey;
    opacity: 80%;
    &:hover{
        .flecheTexte{
            width: 35% !important;
        }
        .texteCache{
            opacity: 1;
        }
        .fleche{
            width: 180% !important;
        }
        width: 90%;
        background-color: #565656;
    }
    @media screen and (max-width: 830px) {
        width: 100%;
        margin-top: 50px;
        opacity: 1;
        .flecheTexte{
            display: none;
        }
        &:hover{
            width: 100%;
        }
    }
}
.texteBarre {
    width: 100%;
    transition: width 1s;
}
.liBG{
    background-color: white;
    position: absolute;
    text-align: center;
    font-size: x-large;
    line-height: 75px;
    border: 2px solid;
    border-radius: 50%;
    margin-left: -37.5px;
    top: 62.5px;
    width: 75px;
    height: 75px;
    z-index: 1;
    left: 10%;
    font-family: Segoe UI,Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
    @media screen and (max-width: 830px) {
        position: initial;
        display: inline-block;
        margin-left: 0px;
        margin-top: 20px;
    }
}
.liTexte{
    display: inline-block;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    font-size: 1.25em;
    font-family: Segoe UI,Roboto, Helvetica, Arial, sans-serif;
    font-weight: 100;
    @media screen and (max-width: 830px) {
        margin-right: 0%;
        margin-left: 0%;
        padding-right: 5%;
        padding-left: 5%;
        width: 100%;
    }
        
}
.gridTissuDroit{
    width: auto !important;
    background-color: #0E3B85;
    cursor: pointer;
    margin-top: 5% !important;
    margin-left: 15% !important;
    margin-bottom: 5% !important;
    &:hover{
        text-decoration: none;
        background-color: #0D2F66;
        .boutonGrid{
            background-color: white;
            color :#0D2F66 !important;
        }
    }
}
.gridTissuGauche{
    width: auto !important;
    background-color: #2e59a1;
    cursor: pointer;
    margin-top: 5% !important;
    margin-right: 15% !important;
    margin-bottom: 5% !important;
    &:hover{
        text-decoration: none;
        background-color: #26477C;
        .boutonGrid{
            background-color: white;
            color :#0D2F66 !important;
        }
    }
}
.itemCache {
    position: relative;
    text-align: center;
    height: 275px;
    z-index: 1;
    .liBG{
        opacity: 1;
        border-color: lightblue;
        animation: liCache 1s;
        @media screen and (max-width: 830px) {
            animation: none;
            border-color: #0E3B85;
        }
    }
    .liTexte{
        opacity: 0.2;
        animation: ligneCache 0.5s;
        @media screen and (max-width: 830px) {
            animation: none;
            opacity: 1;
        }
    }
    .ligne{
        width: 10%;
        @media screen and (max-width: 830px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 830px) {
        opacity: 1;
        height: auto;
        margin-bottom: 20px;
    }
}
.itemOuvert{
    position: relative;
    text-align: center;
    width: 100%;
    opacity: 1;
    height: 200px;
    z-index: 1;
    margin-bottom: 75px;
    .liBG{
        border-color: #0E3B85;
        animation: liOuvert 2s;
        @media screen and (max-width: 830px) {
            animation: none;
        }
    }
    .liTexte{
        animation: ligneOuvert 0.5s;
        @media screen and (max-width: 830px) {
            animation: none;
        }
    }
    .ligne{
        width: 100%;
    }
    @media screen and (max-width: 830px) {
        opacity: 1;
        height: auto;
        margin-bottom: 20px;
    }
}

@keyframes ligneCache{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0.2;
    }
}
@keyframes ligneOuvert{
    0%{
        opacity: 0.2;
    }
    100%{
        opacity: 1;
    }
}
@keyframes liCache{
    0%{
        border-color: #0E3B85;
    }
    100%{
        border-color: lightblue;
    }
}
@keyframes liOuvert{
    0%{
        transform: scale(1, 1);
        border-color: lightblue;
    }
    50%{
        transform: scale(1.5, 1.5);
        border-color: #7390BF;
    }
    100%{
        transform: scale(1, 1);
        border-color: #0E3B85;
    }
}
.ligne{
    width: 10%;
    background-color: #0E3B85;
    height: 2px;
    transition: width 1.5s ease;
    @media screen and (max-width: 830px) {
        transition: none;
        width: 100%;
    }
}
.titre {
    display: inline-block;
    margin: 0;
    margin-top: 10px;
    width: 100%;
    font-size: 2em;
    align-self: center;
    font-weight: 300;
    font-family: Segoe UI,Roboto, Helvetica, Arial, sans-serif;
}

.titrePage{
    border-bottom: 2px solid #0E3B85;
    width: auto;
    margin-left: 10px;
}

.Banner
{
    height: 630px;
    position: relative;

    .Media
    {
        background-color: white;
        height: 100%;
        overflow: hidden;
        position: relative;

        .MediaCaption
        {
            text-overflow: ellipsis;

            position: absolute;
            bottom: 0;

            padding: 15px;

            background-color: black;
            color: white;
            opacity: 0.6;

            width: 100%;
            height: 10%;

            font:
            {
                size: $header-font-size;
                weight: 200;
            }

            transition: 300ms;
            cursor: pointer;
            &:hover
            {
                opacity: 0.8;
            }
            
        }

        transition: 300ms;
        cursor: pointer;
        &:hover
        {
            filter: brightness(115%);
        }
    }

    .BannerGrid
    {
        height: 100%;
    }

    .Content
    {
        color: white;
        background-color: $dark-red;
        height: 100%;

        cursor: pointer;

        padding: 30px;

        transition: 300ms;

        &:hover, &:active
        {
            background-color: $dark-red-active;

            .ViewButton
            {
                background-color: $almost-white;
                color: $dark-red
            }
        }

        .Title
        {
            font-size: $huge-font-size;
            font-weight: 500;
        }

        .Caption
        {
            margin-top: 10px;
            font-size: $bigger-font-size;
        }

        .ViewButton
        {
            margin-top: 40px;
            color: white;

            font-size: $header-font-size;
            border: 3px solid white;
            text-transform: capitalize;

            transition: 200ms;
        }
    }
}
